import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class FlatFileService {

    constructor(private http: HttpClient) { }

    downloadFile(url: string): Observable<HttpEvent<any>> {
        return this.http.get(`${url}`, { responseType: 'blob', reportProgress: true,
        observe: 'events', headers: { accept: 'application/octet-stream' }});
    }
}
