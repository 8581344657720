import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-router.module';
import { OAuthModule } from 'angular-oauth2-oidc';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

import { UserProfileFilterPipe } from './shared/pipes/userprofilefilter.pipe';
import { FooterLinksSortPipe } from './shared/pipes/footerlinks.pipe';
import { CookieService } from 'ngx-cookie-service';
import { AppService} from '../app/app.service';
import { HeaderService } from './components/header/header.service';
import { SafePipe } from './shared/pipes/safe.pipe';
import { LobListComponent } from './components/lob-list/lob-list.component';
import { LobListService } from './components/lob-list/lob-list.service';
import { FlatFileService } from './components/flat-file-component/flat-file-service';
import { FlatFileComponent } from './components/flat-file-component/flat-file-component';
import { VizContainerComponent } from './components/viz-container/viz-container.component';
import { UnauthorizeComponent } from './components/unauthorize/unauthorize.component';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    OAuthModule.forRoot(),
    RouterModule.forRoot([]),
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    UserProfileFilterPipe,
    FooterLinksSortPipe,
    SafePipe,
    LobListComponent,
    FlatFileComponent,
    VizContainerComponent,
    UnauthorizeComponent
  ],
  providers: [
    HeaderService,
    CookieService,
    AppService,
    LobListService,
    FlatFileService
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
