import { Component } from '@angular/core';
import { HeaderService } from '../header/header.service';
import { IProfileLinks } from '../../../models/profilelink.model';
import { ISO_FOOTER_FILTER_ARGS } from '../../shared/constants/app.constants';
import { MenuResponse } from 'src/models/menu.model';

@Component({
    selector: "app-footer",
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})

export class FooterComponent {

  footerLinks = { corporateHome: '', termsAndConditions: '', privacyAndPolicy: '', contactUs: '' };
  errorMessage = '';
  lastHeight: number = 0;
  footerFilterArgs = ISO_FOOTER_FILTER_ARGS;
  userProfileLinks: IProfileLinks[];
  currentYear = new Date().getFullYear();

  constructor(
    private headerService: HeaderService) {
      this.headerService.getMenuItems().subscribe((data: MenuResponse) => {
        this.setUserProfile(data.userProfileLinks);
      });
  }

  setUserProfile(userProfileLinks) {
    userProfileLinks.forEach(item => {
      if (item.code === 'ISO_HOME') {
        this.footerLinks.corporateHome = item.url;
      }
      if (item.code === 'TERMS') {
        this.footerLinks.termsAndConditions = item.url;
      }
      if (item.code === 'POLICY') {
        this.footerLinks.privacyAndPolicy = item.url;
      }
      if (item.code === 'CONTACT') {
        this.footerLinks.contactUs = item.url;
      }   
    });
  }

}
