import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FlatFileComponent } from './components/flat-file-component/flat-file-component';
import { LobListComponent } from './components/lob-list/lob-list.component';
import { VizContainerComponent } from './components/viz-container/viz-container.component';
import { UnauthorizeComponent } from './components/unauthorize/unauthorize.component';

const appRoutes : Routes = [
    { path: '', component: LobListComponent },
    { path: 'dashboard', component: VizContainerComponent },
    { path: 'download', component: FlatFileComponent },
    { path: 'unauthorized', component: UnauthorizeComponent },
    { path: '**', redirectTo: '' }
];
@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
