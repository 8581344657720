import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { CookieService } from 'ngx-cookie-service';
import { MenuResponse } from 'src/models/menu.model';
import { IConfig } from 'src/models/config';

@Injectable()
export class HeaderService {

    private menuItems = new BehaviorSubject<MenuResponse>(new MenuResponse());
    menu$ = this.menuItems.asObservable();
    appConfig: IConfig;

    constructor(private http: HttpClient, private appService: AppService, private cookieService: CookieService) {
        const userId: string = this.cookieService.get('userid');
        const ticket: string = this.cookieService.get('ticket');
        this.appService.getConfigStatus().subscribe(
            configStatus => {
                if (configStatus) {
                    this.appConfig = this.appService.getGlobalConfig();
                    this.getMenu(this.appConfig.marketwatchApiUrl, userId, ticket).subscribe((data: MenuResponse) => {
                        this.menuItems.next(data);
                    });
                }
            });
        }

        getMenu(apiUrl: string, userId: string, ticket: string): Observable<MenuResponse> {
            return this.http.post<MenuResponse>(`${apiUrl}/api/menu`, {userId, ticket});
        }

        getMenuItems(): Observable<MenuResponse> {
            return this.menu$;
        }

}
